<template>
  <div class="auctionMangerPage">
    <div class="main">
       <div class="top_view">
        <a-button
          type="primary"
          class="newLotsBtn"
          style="float: left"
          @click="addClick"
          >新建活动</a-button
        >
        <div class="top_right_view">
            <a-space>
              <a-input-search
                v-model="searchText"
                placeholder="请输入活动名称进行查询"
                enter-button
                allow-clear
                @search="onSearch"
                class="search_View"
              />
              <a-select
                v-model="changStatus"
                style="width: 130px"
                ref="select"
                @change="changStatusClick"
              >
                <a-select-option value="">显示全部状态</a-select-option>
                <a-select-option :value="1">正常</a-select-option>
                <a-select-option :value="0">未启用</a-select-option>
              </a-select>
              <a-select
                v-model="changPublishStatus"
                style="width: 130px"
                ref="select"
                @change="changPublishStatusClick"
              >
                <a-select-option value="">显示全部类型</a-select-option>
                <a-select-option :value="1">公开</a-select-option>
                <a-select-option :value="0">私有</a-select-option>
              </a-select>
            </a-space>
          </div>
      </div>
      <a-table
        :columns="columns"
        :scroll="{ x: 1850 }"
        :data-source="tableData"
        bordered
        :rowKey="record => record.id"
        :loading="tableLoad"
        :pagination="false"
      >
        <template slot="publishStatus" slot-scope="text, record">
          <span v-if="record.publishStatus === 0">私有</span>
          <span v-if="record.publishStatus === 1">公开</span>
        </template>

       <div class="tdDiv" slot="pagePath" slot-scope="text, record">
            <span class="titleSpan" id="tkl">pages/activity/detail?isshare=1&activityId={{text}}</span>  
            <div class="copyDiv" @click="copyFun(text,record)">
                <a-icon type="copy"/>
                <span>复制</span>
            </div> 
        </div>

        <template slot="action" slot-scope="text, record">
          <div class="action_btn_view">
            <a-space>
              <a-button @click="editorFun(record)" class="action_btn pointer"
                >编辑</a-button
              >
              <template v-if="record.status === 1">
                <a-popconfirm
                  title="确定禁用该记录吗？小程序端将不可见？"
                  ok-text="确认"
                  cancel-text="取消"
                  @confirm="contentStutas(record)"
                >
                  <a-button color="green" class="zc_class">
                    正常
                  </a-button>
                </a-popconfirm>
              </template>
              <template v-if="record.status === 0">
                <a-popconfirm
                  title="确定启用该记录吗？小程序端将可见？"
                  ok-text="确认"
                  cancel-text="取消"
                  @confirm="contentStutas(record)"
                >
                  <a-button class="pointer">
                    未启用
                  </a-button>
                </a-popconfirm>
              </template>
            </a-space>
          </div>
        </template>
      </a-table>
      <div class="page_warp">
        <a-pagination
          class="pagination"
          v-model="page.current"
          :total="page.total"
          show-less-items
          :defaultPageSize="page.size"
          @change="pageCurrentChange"
        />
      </div>
    </div>
    <!-- 编辑弹出框 -->
    <a-modal
      width="75%"
      v-model="isAdd"
      :title="header_title"
      okText="确认"
      cancelText="取消"
      @cancel="cancel"
      :maskClosable="false"
      :keyboard="false"
    >
      <div class="contentLoadingWarp" v-if="contentLoading">
        <a-spin />
      </div>
      <div class="modal_content" v-if="!contentLoading">
        <a-form-model
          layout="horizontal"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 14 }"
          :model="formData"
          :rules="rules"
          ref="contentForm"
        >
          <a-form-model-item label="活动id" prop="thirdActivityId">
            <a-input v-model="formData.thirdActivityId" :maxLength="50" placeholder="请输入活动id"/>
          </a-form-model-item>
          <a-form-model-item label="Guest list id" prop="guestListId">
            <a-input v-model="formData.guestListId" :maxLength="50" placeholder="请输入guest list id"/>
          </a-form-model-item>
          <a-form-model-item label="公开/私有" prop="publishStatus">
            <a-select v-model="formData.publishStatus" placeholder="请选择类型">
              <a-select-option :value="0">
                私有
              </a-select-option>
              <a-select-option :value="1">
                公开
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="封面图" prop="cover">
            <a-upload
              listType="picture-card"
              class="upload_btn"
              :fileList="coverList"
              :customRequest="uploadCover"
              @preview="handlePreview"
              :remove="delCover"
              accept="image/*"
            >
              <div v-if="coverList.length < 1">
                <a-icon :type="loading ? 'loading' : 'upload'" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="previewCancel"
              title="预览图片"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-model-item>
          <a-form-model-item prop="contentHtml" :wrapper-col="{ span: 19 }">
            <span slot="label">
              活动介绍
              <a-tooltip
                title="点击右侧按钮，插入对应的内容类型，可通过拖拽进行排序"
              >
                <a-icon type="exclamation-circle" />
              </a-tooltip>
            </span>
              <RitchText ref="ritchText" v-model="formData.contentHtml"  minProgramType="MP1" ></RitchText>

          </a-form-model-item>
        </a-form-model>
      </div>
      <span slot="footer">
        <a-button @click="priviewFun(2)">预览</a-button>
        <a-button type="primary" @click="submitData">保存</a-button>
      </span>
    </a-modal>

    <!--    手机预览弹窗-->
    <mobile-preview
      :is-show="isShowPriview"
      @onCancel="onCancel"
      :preview-data="previewData"
    ></mobile-preview>
    <!--    上传加载进度弹窗-->
    <loading-modal
      :loadProcess="loadProcess"
      :loadingModal="loadingModal"
    ></loading-modal>
    <imagePreview
      :imgPreviewUrl="imgPreviewUrl"
      :isImgPreview="isImgPreview"
      @cancel="imgPreviewCancel"
    ></imagePreview>
    <div class="mark_div" v-if="submitLoading">
      <a-spin />
    </div>
  </div>
</template>

<script>
import API from "@/request";
import moment from "moment";
import MobilePreview from "@/components/mobilePreview";
import upload from "@/utils/upload";
import LoadingModal from "@/components/loadingModal";
import imagePreview from "@/components/imagePreview";
import RitchText from '../content/components/ritchText'
export default {
  components: {
    LoadingModal,
    MobilePreview,
    imagePreview,
    RitchText
  },
  name: "auctionMangerPage",
  data() {
    return {
      submitLoading:false,//控制新建/编辑 活动时候loading字段
      contentLoading: false, // 控制新增内容弹窗loading效果
      imgPreviewUrl: "", // 卡片区域图片预览存放地址
      isImgPreview: false, // 图片区域弹窗显示隐藏对应字段
      loadProcess: 0, // 控制上传进度条
      loadingModal: false, // 控制上传进度弹窗显示
      coverList: [], // 上传首页图列表
      previewImage: "", // 首页图预览字段
      previewVisible: false, //控制首页图预览显示隐藏字段
      loading: false, // 封面图上传加载效果控制字段
      tableLoad: false, // 表格数据加载效果

      previewData: {}, //手机预览传入的数据
      isSubmitInfo: false, //点击新建可能会重复提交数据
      rules: {
        // 表单验证规则
        publishStatus: [
          {
            required: true,
            message: "请选择类型",
            trigger: "change"
          }
        ],
        thirdActivityId: [
          {
            required: true,
            message: "请输入活动id",
            trigger: "blur"
          }
        ],
        guestListId: [
          {
            required: true,
            message: "请输入Guest list id",
            trigger: "blur"
          }
        ],
        cover: [
          {
            required: true,
            message: "请上传封面图",
            trigger: "blur"
          }
        ],
        contentHtml: [
          {
            required: true,
            message: "请填写内容",
            trigger: "blur"
          }
        ]
      },
      formData: {
        // 表单数据
        thirdActivityId: "",
        guestListId:'',
        publishStatus: "",
        cover: [],
        contentHtml: '',
      },
      page: {
        // 表格分页相关
        total: 0,
        current: 1,
        size: 10
      },
      tableData: [], // 表格数据
      changStatus: "", // 表格右上类型筛选绑定字段
      changPublishStatus:'',//筛选公开私有字段
      columns: [
        // 定义表格字段
        {
          title: "活动名称",
          dataIndex: "activityName",
        },
        {
          title: "活动id",
          width:110,
          dataIndex: "thirdActivityId",
        },
        {
          title: "Guest list id",
          width:110,
          dataIndex: "guestListId",
        },
        {
          title: "公开/私有",
          dataIndex: "publishStatus",
          width:100,
          scopedSlots: { customRender: "publishStatus" }
        },
        {
          title: "活动城市",
           width:130,
          dataIndex: "city",
          customRender: text => {
            if (text) {
              return text;
            }else{
              return "-";
            }
          }
        },
        {
          title: "活动开始时间",
          dataIndex: "startTime",
          width: 170,
          customRender: text => {
            if (text) {
              return moment(Number(text)).format("YYYY/MM/DD HH点mm分");
            }else{
              return "-";
            }
          }
        },
        {
          title: "活动结束时间",
          dataIndex: "endTime",
          width: 170,
          customRender: text => {
            if (text) {
              return moment(Number(text)).format("YYYY/MM/DD HH点mm分");
            }else{
              return "-";
            }
          }
        },
        {
          title: "小程序page path",
          dataIndex: "id",
          width: 530,
          scopedSlots: { customRender: "pagePath" }
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 180,
          scopedSlots: { customRender: "action" }
        }
      ],
      searchText: "", // 表格右上搜索框绑定字段
      isAdd: false, // 控制新增表单弹窗控制字段
      header_title: "新增拍品",
      isShowPriview: false
    };
  },
  methods: {
    pageCurrentChange(val) {
      // 分页页码变化
      this.page.current = val;
      this.getList();
    },
    imgPreviewCancel() {
      this.isImgPreview = false;
    },
    dateFormat(val) {
      return moment(val).format("YYYY年MM月DD日 a HH");
    },



    /**
     *  新增模块类型  type  1 文字 2 图片 3 视频 4 拍卖会 5 拍品
     * */
    imgPreview(url) {
      // 图片卡片预览
      this.imgPreviewUrl = url;
      this.isImgPreview = true;
    },
    /**
     * 封面图预览
     */
    handlePreview() {
      // 封面图预览方法
      this.previewVisible = true;
      this.previewImage = this.coverList[0].url;
    },
    previewCancel() {
      // 封面图取消预览
      this.previewVisible = false;
      this.previewImage = "";
    },
    // 封面图上传
    uploadCover(action) {
      const file = action.file;
      let size = file.size / 1048576;
      if (size > 20) {
        this.$message.warning("图片文件不能超过20M");
        return;
      }
      let pathName =
        "content/" +
        new Date().valueOf() +
        parseInt(Math.random() * 10000) +
        file.name;
      this.loading = true;
      upload.uploadFile(pathName, file).then(res => {
        this.loading = false;
        this.coverList.push({
          uid: "-1",
          name: "image.png",
          status: "done",
          url: res
        });
        this.formData.cover = this.coverList[0].url;
      });
    },
    /**
     * 新增推文和视频
     */
    addClick() {
      this.header_title = "新建活动";
      this.isAdd = true;
      this.formData = {
        thirdActivityId: "",
        guestListId:'',
        publishStatus: "",
        cover: [],
        contentHtml: '',
      };
    },
    delCover() {
      // 删除封面图
      this.coverList = [];
    },
    /**
     * 编辑活动  根据Id获取活动详情
     */
    async editorFun(record) {
      this.header_title = "编辑活动";

      this.contentLoading = true;
      let res = await API.activityListById({ id: record.id});
      if (res.data.code === "0000") {
        this.isAdd = true;
        // 组装数据
        let base = res.data.data;
        this.coverList = [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: base.cover
          }
        ]; //上传图片列表单独处理
        let data = {
          thirdActivityId: base.thirdActivityId,
          guestListId: base.guestListId,
          publishStatus: base.publishStatus,
          cover: base.cover,
          contentHtml: base.content,
          id:base.id
        };
        this.contentLoading = false;
        this.formData = data;
      }
    },
    onSearch(val) {
      this.page.current = 1
      this.page.size = 10
      this.searchText = val;
      this.getList();
    },
    changStatusClick(val) {
      this.changStatus = val;
      this.getList();
    },
    changPublishStatusClick(val) {
      this.changPublishStatus = val;
      this.getList();
    },
    /**
     * 弹出框提交数据  新建
     */
    submitData() {
      if(this.formData.id){
        this.editActivity()
      }else{
        this.addActivity()
      }
    },
    //新建活动
    async addActivity(){
      this.$refs.contentForm.validate(async valid => {
        if (valid) {
          if (this.isSubmitInfo) {
            this.$message.warning("新增中，请勿重复提交！");
            return;
          }
          this.isSubmitInfo = true;
          let params = {
            publishStatus: this.formData.publishStatus,
            thirdActivityId: this.formData.thirdActivityId,
            cover: this.formData.cover,
            content: this.formData.contentHtml,
            guestListId: this.formData.guestListId,
          };
          this.submitLoading = true
          let res = await API.addActivityList(params).catch((err) => {
            this.isSubmitInfo = false;
          });
          this.submitLoading = false
          if (res && res.data.code === "0000") {
            this.$message.success('新增成功');
            this.isSubmitInfo = false;
            this.isAdd = false;
            this.formData = {
              thirdActivityId: "",
              guestListId:'',
              publishStatus: "",
              cover: [],
              contentHtml: '',
            };
            this.$refs.ritchText.clearHtml()
            this.coverList = [];
            this.getList();
          }
        }
      });
    },
    //编辑活动
    async editActivity(){
      this.$refs.contentForm.validate(async valid => {
        if (valid) {
          if (this.isSubmitInfo) {
            this.$message.warning("编辑中，请勿重复提交！");
            return;
          }
          this.isSubmitInfo = true;
          let params = {
            publishStatus: this.formData.publishStatus,
            thirdActivityId: this.formData.thirdActivityId,
            cover: this.formData.cover,
            content: this.formData.contentHtml,
            guestListId: this.formData.guestListId,
            id:this.formData.id
          };
          this.submitLoading = true
          let res = await API.editActivityList(params).catch((err) => {
            this.isSubmitInfo = false;
          });
          this.submitLoading = false
          if (res && res.data.code === "0000") {
            this.$message.success('编辑成功');
            this.isSubmitInfo = false;
            this.isAdd = false;
            this.formData = {
              thirdActivityId: "",
              guestListId:'',
              publishStatus: "",
              cover: [],
              contentHtml: '',
            };
            this.$refs.ritchText.clearHtml()
            this.coverList = [];
            this.getList();
          }
        }
      });
    },
    handleChange() {},
    async contentStutas(record) {
      let value = record.status === 1 ? 0 : 1;
      let res = await API.updateActivityStatus({
        id: record.id,
        status: value
      });
      if (res.data.code === "0000") {
        this.getList();
      }
    },
    cancel() {

      if(this.$refs.ritchText!=undefined){
        this.$refs.ritchText.clearHtml()
      }
      if(this.$refs.contentForm != undefined){
          this.$refs.contentForm.resetFields();
      }
      this.formData = {
        title: "",
        type: "",
        cover: "",
        createTime: "",
        contentId: "",
        contentHtml: '',
        author:"",
      };
      this.coverList = [];
      this.isSubmitInfo = false;
      // this.formData.createTime = "";
      // this.formData.contentId = "";
    },
     /**
     * 预览
     */
    async priviewFun(type, e) {
      // this.previewData = e;
      // this.isShowPriview = true;
      // type  用来判断是点击表格的预览按钮 还是弹窗里的预览按钮 1 表格 2 弹窗
      if (type === 1) {
        // 如果是表格预览按钮则需根据id去请求内容详细数据
        let res = await API.getContentById({ contentId: e.contentId,groupId:2 });
        if (res) {
          if (res.data.code === "0000") {
            this.isShowPriview = true;
            this.previewData = res.data.data.base;
          }
        }
      } else {

        let data = {
          thirdActivityId: this.formData.thirdActivityId,
          guestListId:this.formData.guestListId,
          publishStatus: this.formData.publishStatus,
          cover: this.formData.cover,
          createTime: this.formData.createTime,
          content: this.formData.contentHtml
        };
        this.isShowPriview = true;
        this.previewData = data;
      }
    },
    onCancel() {
      this.isShowPriview = false;
    },

    // 获取表格数据
    async getList(
      status = this.changStatus,
      publishStatus = this.changPublishStatus,
      activityName = this.searchText,
      current = this.page.current,
      size = this.page.size,
    ) {
      this.tableLoad = true;
      let res = await API.getActivityList({activityName, publishStatus,status,current, size });
      if (res.data.code === "0000") {
        let data = res.data.data;
        this.tableData = data.records;
        this.tableLoad = false;
        this.page = {
          // 设置分页信息
          current: data.current,
          total: data.total,
          size: data.size
        };
      }
    },
    copyFun(text,record){
      // 创建输入框
      var textarea = document.createElement('textarea');
      document.body.appendChild(textarea);
      // 隐藏此输入框
      textarea.style.position = 'absolute';
      textarea.style.clip = 'rect(0 0 0 0)';
      // 赋值
      textarea.value =`pages/activity/detail?isshare=1&activityId=${text}`;
      // 选中
      textarea.select();
      // 复制
      document.execCommand('copy', true);
      document.body.removeChild(textarea);
      this.$message.success('复制成功!');
    }
  },
  computed: {},

  created() {},
  mounted() {
    this.getList(); // 请求表格数据
  }
};
</script>

<style lang="less" scoped>
/deep/.has-error .ant-upload.ant-upload-select-picture-card {
  border: 1px dashed #f5222d !important;
}
.contentLoadingWarp {
  display: flex;
  justify-content: center;
}
.loading_warp {
  display: flex;
  flex-flow: column;
  justify-content: center;
  .loading_text {
    text-align: center;
  }
}
.picView {
  max-width: 350px;
  max-height: 200px;
}
.videoPlater {
  outline: none;
  max-width: 300px;
  max-height: 200px;
}
.pageHead {
  background: white;
}

.main {
  margin: 24px;
  background: #ffffff;
  padding: 24px;
}

.newLotsBtn {
  margin-bottom: 16px;
}
.modal_content {
  max-height: 600px;
  overflow: auto;
}
.search_View {
  width: 260px;
}

.tab_top_view {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.content_warp {
  .block_warp {
    margin-top: 15px;
    border: 1px solid #cccccc;
    background: #ffffff;
    border-radius: 10px;
    width: 100%;
    padding: 15px 10px;
    display: flex;
    .block_left {
      flex: 1;

      .auctionItems {
        border: 1px solid #bbbbbb;
        width: 383px;
        display: flex;
        box-sizing: border-box;
        padding: 10px;
        border-radius: 6px;
        .pic {
          display: flex;
          align-items: center;
          margin-right: 7px;
          img {
            height: 105px;
            width: 85px;
            border: none;
          }
        }
        .auctionItem_content {
          .auctionItem_header {
            font-size: 13px;
            line-height: 18px;
            margin-top: 8px;
            .serial {
              color: #919298;
              margin-right: 14px;
            }
            .brand {
              color: #101010;
            }
          }
          .auctionItem_title {
            line-height: 18px;
            margin-top: 8px;
            color: #101010;
          }
        }
      }
    }
    .block_right {
      box-sizing: border-box;
      padding-left: 25px;
      display: flex;
      //align-items: flex-end;
    }
  }
}
.auctions {
  // width: 368px;
  .auctions_top {
    box-sizing: border-box;
    padding: 0 13px;
    background: #f7f0f0;
    border: 1px solid #bbbbbb;
    .auctions_title {
      font-weight: 600;
      color: #000000;
      font-size: 18px;
      magrin-bottom: 7px !important;
    }
    .auctions_warp {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
      padding-bottom: 20px;
      img {
        display: block;
        height: 116px;
        width: 107px;
        background: #ffffff;
      }
      &:after {
        content: "";
        height: 0;
        width: 107px;
      }
    }
  }
  .auctions_date {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #101010;
    padding: 0 13px;
    box-sizing: border-box;
    border: 1px solid #bbbbbb;
    border-top: none;
    .date_icon {
      margin-right: 6px;
      font-size: 18px;
      color: #0090fa;
    }
    .line {
      margin: 0 7px;
    }
  }
}
.top_view {
  display: flex;
  justify-content: space-between;
}
.zc_class {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.page_warp {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
.tdDiv{
    display: flex;
    align-items: center;
    .titleSpan{
        flex: 1;
        margin-right: 10px;
    }
    .copyDiv{
        cursor: pointer;
        display: flex;
        flex-direction: column;
        width: 30px;
        color: #00263e;
        font-size: 13px;
    }
}
.mark_div{
  width:100%;
  height:100%;
  position: fixed;
  top:0;
  left:0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
}
</style>
